<template>
  <div id="app">
    <header>
      <div class="header-content">
        <h1>{{ t.schoolName }}</h1>
        <nav>
          <ul>
            <li>
              <router-link to="/">{{ t.nav.home }}</router-link>
            </li>
            <li>
              <router-link to="/career">{{ t.nav.career }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{ t.nav.contact }}</router-link>
            </li>
          </ul>
        </nav>
        <button @click="toggleLanguage">
          {{ currentLang === "en" ? "中文" : "English" }}
        </button>
      </div>
    </header>

    <main>
      <RouterView />
    </main>

    <footer>
      <p>&copy; 2020 {{ t.schoolName }} All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import translations from "./translations.json";
import { storeToRefs } from "pinia";
import { useLanguageStore } from "./stores/language.ts";

export default {
  name: "App",
  data() {
    return {
      currentLang: "en",
      translations,
    };
  },
  computed: {
    t() {
      const { currentLang } = storeToRefs(useLanguageStore());
      return this.translations[currentLang.value];
    },
  },
  methods: {
    toggleLanguage() {
      const languageStore = useLanguageStore();
      languageStore.toggleLanguage();
    },
    submitForm() {
      console.log("Form submitted:", this.form);
    },
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  color: #333;
}

header {
  background-color: rgba(44, 62, 80, 0.95);
  color: white;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

header.scrolled {
  background-color: rgba(44, 62, 80, 1);
  padding: 0.5rem 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

header h1 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1px;
}

header h1 a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

header h1 a:hover {
  color: #3498db;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

nav ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3498db;
  transition: width 0.3s ease;
}

nav ul li a:hover {
  color: #3498db;
}

nav ul li a:hover::after {
  width: 100%;
}

/* Responsive design for header */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  nav {
    margin-top: 1rem;
    width: 100%;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}

main {
  padding: 2rem;
}

section {
  margin-bottom: 2rem;
}

footer {
  background-color: #4a4a4a;
  color: white;
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.program-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  padding: 2rem 0;
}

.program-card {
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.02);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.program-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #3498db, #2ecc71);
}

.program-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.02);
}

.program-card h3 {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.program-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.program-card ul {
  margin: 1.5rem 0;
  padding-left: 0;
  list-style: none;
}

.program-card ul li {
  position: relative;
  padding-left: 1.8rem;
  margin-bottom: 0.8rem;
  color: #555;
  font-size: 0.95rem;
}

.program-card ul li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #3498db;
  font-weight: bold;
}

.age-group {
  display: inline-block;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 10px rgba(52, 152, 219, 0.2);
}

.hero {
  height: 100vh;
  background-image: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url("https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(52, 152, 219, 0.2),
    rgba(46, 204, 113, 0.2)
  );
  z-index: 1;
}

.hero-content {
  max-width: 800px;
  text-align: center;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  animation: fadeIn 1s ease-out;
}

.hero h2 {
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hero h2 span {
  display: block;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4.5rem;
  margin-top: 0.5rem;
}

.hero p {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2.5rem;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.cta-button {
  display: inline-block;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.4);
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.6s;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(52, 152, 219, 0.6);
}

.cta-button:hover::before {
  left: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    padding: 1rem;
    height: auto;
    min-height: 100vh;
  }

  .hero-content {
    padding: 2rem;
  }

  .hero h2 {
    font-size: 2.5rem;
  }

  .hero h2 span {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .cta-button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
  }
}

/* Optional: Add a subtle animation for the background */
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(52, 152, 219, 0.1) 0%,
    rgba(46, 204, 113, 0.1) 50%,
    rgba(52, 152, 219, 0.1) 100%
  );
  background-size: 200% 200%;
  animation: gradientBG 15s ease infinite;
  z-index: 1;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* About Us Section Styles */
#about {
  padding: 6rem 0;
  /* background: #f8f9fa; */
  position: relative;
  overflow: hidden;
}

#about::before {
  content: "";
  position: absolute;
  top: -50%;
  right: -50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(52, 152, 219, 0.05) 0%,
    transparent 70%
  );
  transform: rotate(-15deg);
}

/* #about h2 {
  font-size: 2.8rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  font-weight: 800;
} */

#about h2::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #3498db, #2ecc71);
  border-radius: 2px;
}

.about-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.about-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateY(0);
  transition: all 0.3s ease;
}

.about-content p:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.05);
}

.about-features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.about-features li {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  transform: translateX(0);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.about-features li::before {
  content: "→";
  margin-right: 1rem;
  color: #3498db;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.about-features li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  border-radius: 2px;
}

.about-features li:hover {
  transform: translateX(5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.05);
}

.about-features li:hover::before {
  transform: translateX(5px);
  color: #2ecc71;
}

/* Animation for features */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.about-features li {
  animation: slideIn 0.5s ease-out forwards;
  opacity: 0;
}

.about-features li:nth-child(1) {
  animation-delay: 0.1s;
}
.about-features li:nth-child(2) {
  animation-delay: 0.2s;
}
.about-features li:nth-child(3) {
  animation-delay: 0.3s;
}
.about-features li:nth-child(4) {
  animation-delay: 0.4s;
}
.about-features li:nth-child(5) {
  animation-delay: 0.5s;
}

/* Responsive Design */
@media (max-width: 968px) {
  .about-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  #about {
    padding: 4rem 0;
  }

  #about h2 {
    font-size: 2.2rem;
  }

  .about-content p,
  .about-features li {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  #about {
    padding: 3rem 0;
  }

  .about-content {
    padding: 0 1rem;
  }

  .about-content p {
    padding: 1.5rem;
  }

  .about-features li {
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

/* Optional: Add a floating animation to the background */
@keyframes float {
  0% {
    transform: translateY(0) rotate(-15deg);
  }
  50% {
    transform: translateY(-20px) rotate(-15deg);
  }
  100% {
    transform: translateY(0) rotate(-15deg);
  }
}

#about::before {
  animation: float 15s ease-in-out infinite;
}

button {
  background: linear-gradient(135deg, #3498db, #2ecc71);
  color: white;
  border: none;
  padding: 0.7rem 1.5rem;
  border-radius: 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 10px rgba(52, 152, 219, 0.2);
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.3);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.program-card {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.program-card:nth-child(1) {
  animation-delay: 0.1s;
}
.program-card:nth-child(2) {
  animation-delay: 0.2s;
}
.program-card:nth-child(3) {
  animation-delay: 0.3s;
}
.program-card:nth-child(4) {
  animation-delay: 0.4s;
}
.program-card:nth-child(5) {
  animation-delay: 0.5s;
}
.program-card:nth-child(6) {
  animation-delay: 0.6s;
}
.program-card:nth-child(7) {
  animation-delay: 0.7s;
}

/* Responsive Design Improvements */
@media (max-width: 768px) {
  .hero h2 {
    font-size: 2.5rem;
  }

  .program-grid {
    gap: 1.5rem;
  }

  .contact-content {
    padding: 1.5rem;
  }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #3498db, #2ecc71);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #2980b9, #27ae60);
}

/* People Section Styles */
.people-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.people-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.people-card:hover {
  transform: translateY(-10px);
}

.people-image {
  width: 150px;
  height: 150px;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #3498db;
}

.people-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.people-card h3 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.people-card .role {
  color: #3498db;
  font-weight: 600;
  margin-bottom: 1rem;
}

.people-card .bio {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-links a {
  color: #666;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #3498db;
}

/* Career Section Styles */
.careers-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
}

.careers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.career-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.career-card h3 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.location {
  display: inline-block;
  background: #f0f0f0;
  padding: 0.3rem 1rem;
  border-radius: 15px;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.requirements {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
}

.requirements li {
  margin-bottom: 0.5rem;
  color: #666;
}

.apply-button {
  display: inline-block;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 25px;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.apply-button:hover {
  transform: translateY(-2px);
}

/* Enhanced Contact Section Styles */
.contact-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-card {
  display: flex;
  align-items: flex-start;
  background: white;
  padding: 1.5rem;
  border-radius: 15px;
  margin-bottom: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.contact-card i {
  font-size: 1.5rem;
  color: #3498db;
  margin-right: 1rem;
}

.contact-details h3 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.contact-details p {
  color: #666;
  margin-bottom: 0.5rem;
}

.contact-form {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-wrapper {
    grid-template-columns: 1fr;
  }

  .people-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
</style>
