<template>
  <div>
    <section id="home" class="hero">
      <div class="hero-content">
        <h2>
          {{ t.hero.welcome }} <br />
          <span>{{ t.schoolName }}</span>
        </h2>
        <p>{{ t.hero.tagline }}</p>
        <a href="#programs" class="cta-button">{{ t.cta }}</a>
      </div>
    </section>

    <section id="about" class="container">
      <h2>{{ t.about.title }}</h2>
      <div class="about-content">
        <p>{{ t.about.description }}</p>
        <ul class="about-features">
          <li v-for="(feature, index) in t.about.features" :key="index">
            {{ feature }}
          </li>
        </ul>
      </div>
    </section>

    <section id="programs" class="container">
      <h2>{{ t.programs.title }}</h2>
      <div class="program-grid">
        <div class="program-card">
          <h3>{{ t.programs.introPython.title }}</h3>
          <p>{{ t.programs.introPython.description }}</p>
          <ul>
            <li
              v-for="(topic, index) in t.programs.introPython.topics"
              :key="index"
            >
              {{ topic }}
            </li>
          </ul>
          <span class="age-group">{{ t.programs.introPython.ageGroup }}</span>
        </div>
        <div class="program-card">
          <h3>{{ t.programs.advJava.title }}</h3>
          <p>{{ t.programs.advJava.description }}</p>
          <ul>
            <li
              v-for="(topic, index) in t.programs.advJava.topics"
              :key="index"
            >
              {{ topic }}
            </li>
          </ul>
          <span class="age-group">{{ t.programs.advJava.ageGroup }}</span>
        </div>
        <div class="program-card">
          <h3>{{ t.programs.apcsa.title }}</h3>
          <p>{{ t.programs.apcsa.description }}</p>
          <ul>
            <li v-for="(topic, index) in t.programs.apcsa.topics" :key="index">
              {{ topic }}
            </li>
          </ul>
          <span class="age-group">{{ t.programs.apcsa.ageGroup }}</span>
        </div>
        <div class="program-card">
          <h3>{{ t.programs.cccJunior.title }}</h3>
          <p>{{ t.programs.cccJunior.description }}</p>
          <ul>
            <li
              v-for="(topic, index) in t.programs.cccJunior.topics"
              :key="index"
            >
              {{ topic }}
            </li>
          </ul>
          <span class="age-group">{{ t.programs.cccJunior.ageGroup }}</span>
        </div>
        <div class="program-card">
          <h3>{{ t.programs.cccSenior.title }}</h3>
          <p>{{ t.programs.cccSenior.description }}</p>
          <ul>
            <li
              v-for="(topic, index) in t.programs.cccSenior.topics"
              :key="index"
            >
              {{ topic }}
            </li>
          </ul>
          <span class="age-group">{{ t.programs.cccSenior.ageGroup }}</span>
        </div>
        <div class="program-card">
          <h3>{{ t.programs.cpsc310.title }}</h3>
          <p>{{ t.programs.cpsc310.description }}</p>
          <ul>
            <li
              v-for="(topic, index) in t.programs.cpsc310.topics"
              :key="index"
            >
              {{ topic }}
            </li>
          </ul>
          <span class="age-group">{{ t.programs.cpsc310.ageGroup }}</span>
        </div>
        <div class="program-card">
          <h3>{{ t.programs.newGradInterview.title }}</h3>
          <p>{{ t.programs.newGradInterview.description }}</p>
          <ul>
            <li
              v-for="(topic, index) in t.programs.newGradInterview.topics"
              :key="index"
            >
              {{ topic }}
            </li>
          </ul>
          <span class="age-group">{{
            t.programs.newGradInterview.ageGroup
          }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import translations from "../translations.json";
import { storeToRefs } from "pinia";
import { useLanguageStore } from "../stores/language.ts";

export default {
  name: "HomePage",
  data() {
    return {
      currentLang: "en",
      translations,
    };
  },
  computed: {
    t() {
      const { currentLang } = storeToRefs(useLanguageStore());
      return this.translations[currentLang.value];
    },
  },
  methods: {
    toggleLanguage() {
      const languageStore = useLanguageStore();
      languageStore.toggleLanguage();
    },
  },
};
</script>

<style>
</style>