import { defineStore } from 'pinia'

export const useLanguageStore = defineStore('language', {
  state: () => ({
    currentLang: 'en'
  }),
  actions: {
    toggleLanguage() {
      this.currentLang = this.currentLang === 'en' ? 'zh' : 'en'
    }
  }
}) 